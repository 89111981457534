import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LanguageToggler from "../components/languageToggler"
import styles from "../components/styles/index.module.css"
import Arrow from "../assets/svg/arrow.svg"
import SvgAnimation from "../components/svgAnimation"

const IndexPage = ({ data }) => {
  return (
    <Layout bgColor="#fd9d6e">
      <SEO title="Inicio" />
      <div className={styles.mainContainer}>
        <LanguageToggler />
        <SvgAnimation />
        <nav className={styles.nav}>
          <a
            className={styles.navItem}
            href="https://estudioobliquo.com/#proyectos"
            target="_blank"
          >
            Diseño y<br /> desarrollo web
            <Arrow />
          </a>
          <a
            className={styles.navItem}
            href="https://linktr.ee/puntodefugapodcast"
            target="_blank"
          >
            Producción de<br /> contenido digital
            <Arrow />
          </a>
          <a className={styles.navItem} href="#info">
            info + contacto
            <Arrow />
          </a>
        </nav>
        <div className={styles.imgContainer}>
          <Img fluid={data.indeximg.childImageSharp.fluid} />
        </div>
        <a
          className={styles.mail}
          href="mailto:contacto@lucasboh.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          contacto@lucasboh.com
        </a>
        <div className={styles.info} id="info">
          <h2>Acerca de</h2>
          <p>
            Soy Lucas Boh, de Asunción, Paraguay, y actualmente vivo en
            Maastricht, Países Bajos, cursando el Máster en Políticas Públicas y Desarrollo Humano en UNU-MERIT / Maastricht University. Soy ingeniero en medios de comunicación (B.
            Eng. Medientechnik) y cofundador del{" "}
            <a
              className="wave"
              href="https://estudioobliquo.com/"
              target="_blank"
            >
              Estudio Obliquo
            </a>
            , donde trabajo como diseñador y desarrollador de páginas web y
            sistemas.
          </p>
          <p>
            También soy cocreador y productor de{" "}
            <a
              className="wave"
              href="https://linktr.ee/puntodefugapodcast"
              target="_blank"
            >
              Punto de Fuga
            </a>
            , una plataforma de periodismo independiente que crea contenido digital, en forma de podcasts y videos, que buscan explicar de manera objetiva situaciones y problemas complejos que se desarrollan en Paraguay.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    indeximg: file(relativePath: { eq: "index.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
