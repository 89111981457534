import React, { useState, useEffect } from "react"
import styles from "../components/styles/index.module.css"

const SvgAnimation = ({ siteTitle }) => {
  useEffect(() => {
    window.onscroll = () =>
      setScrolled(
        (window.scrollY + window.innerHeight) /
          document.documentElement.scrollHeight
      )
  }, [])
  // valor de cuanto se scrolleo
  const [scrolled, setScrolled] = useState(0)

  // controlador de la className
  const [animate, setAnimate] = useState(0)

  if (scrolled > 0.6 && animate === 0) {
    setAnimate(styles.animate)
  }
  return (
    <>
      <h1 className={styles.title}>
        Lucas Boh
        <span className={[styles.title__punto, animate].join(" ")}>.</span>
      </h1>
      <svg
        className={styles.svgSinus}
        width="155"
        height="862"
        viewBox="0 0 160 862"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(10,0)">
          <path
            id="motionPath2"
            className={[styles.path1, animate].join(" ")}
            d="M69.255 826.442C69.255 826.442 52.4757 818.788 44.2083 814.706C37.2801 811.286 30.1605 808.165 23.6375 804.021C18.5113 800.764 13.3389 797.365 9.16173 792.953C6.41083 790.048 3.86733 786.756 2.40003 783.031C1.78703 781.475 1.48253 779.736 1.63813 778.07C1.95813 774.645 3.10883 771.164 5.06663 768.338C9.83363 761.457 17.3407 756.85 24.3041 752.214C32.6644 746.648 42.0465 742.795 51.0652 738.38C65.077 731.521 79.465 725.456 93.54 718.726C100.216 715.534 107.023 712.577 113.444 708.899C117.763 706.425 121.937 703.68 125.92 700.694C128.787 698.545 131.97 696.605 134.11 693.729C137.033 689.802 140.218 685.171 140.015 680.277C139.788 674.817 135.821 669.962 132.206 665.87C127.348 660.372 120.493 657.013 114.301 653.085C110.399 650.61 106.324 648.408 102.207 646.311C96.445 643.378 90.452 640.925 84.588 638.202C80.169 636.149 69.285 631.004 69.285 631.004"
            stroke="#272727"
            strokeWidth="2"
          />
          <path
            id="motionPath1"
            className={[styles.path2, animate].join(" ")}
            d="M130.921 860.866C126.063 855.368 119.208 852.009 113.016 848.081C109.114 845.606 105.039 843.404 100.922 841.307C95.16 838.374 89.167 835.921 83.303 833.198C78.884 831.145 68 826 68 826"
            stroke="#272727"
            strokeWidth="2"
          />
          <path
            id="motionPath3"
            className={[styles.path3, animate].join(" ")}
            d="M69.274 630.996C69.274 630.996 52.4947 623.343 44.2273 619.261C37.2991 615.84 30.1796 612.719 23.6565 608.575C18.5303 605.319 13.3579 601.92 9.18078 597.508C6.42978 594.602 3.88628 591.311 2.41908 587.586C1.80598 586.029 1.50158 584.29 1.65718 582.624C1.97718 579.199 3.12788 575.719 5.08568 572.893C9.85268 566.012 17.3598 561.405 24.3232 556.769C32.6835 551.203 42.0655 547.35 51.0843 542.935C65.096 536.075 79.484 530.01 93.559 523.281C100.235 520.089 107.042 517.132 113.463 513.454C117.782 510.98 121.956 508.234 125.939 505.249C128.806 503.099 131.989 501.16 134.129 498.284C137.052 494.357 140.237 489.726 140.034 484.831C139.807 479.371 135.84 474.517 132.225 470.425C127.367 464.927 120.512 461.568 114.32 457.64C110.418 455.164 106.343 452.963 102.226 450.866C96.464 447.932 90.471 445.479 84.607 442.756C80.188 440.704 69.304 435.559 69.304 435.559"
            stroke="#272727"
            strokeWidth="2"
          />
          <path
            id="motionPath4"
            className={[styles.path4, animate].join(" ")}
            d="M69.303 435.557C69.303 435.557 52.5233 427.904 44.2559 423.822C37.3277 420.401 30.2082 417.28 23.6851 413.136C18.5589 409.88 13.3865 406.481 9.20929 402.069C6.45839 399.164 3.91489 395.872 2.44759 392.147C1.83459 390.59 1.53008 388.851 1.68578 387.186C2.00568 383.761 3.15639 380.28 5.11419 377.454C9.88119 370.573 17.3883 365.966 24.3517 361.33C32.712 355.764 42.0941 351.911 51.1128 347.496C65.125 340.636 79.513 334.571 93.588 327.842C100.263 324.65 107.071 321.693 113.492 318.015C117.81 315.541 121.985 312.795 125.968 309.81C128.835 307.66 132.018 305.721 134.158 302.845C137.08 298.918 140.266 294.287 140.063 289.392C139.836 283.933 135.869 279.078 132.253 274.986C127.396 269.488 120.541 266.129 114.349 262.201C110.447 259.726 106.372 257.524 102.254 255.427C96.493 252.493 90.499 250.04 84.636 247.317C80.216 245.265 69.333 240.12 69.333 240.12"
            stroke="#272727"
            strokeWidth="2"
          />
          <path
            id="motionPath5"
            className={[styles.path5, animate].join(" ")}
            d="M69.238 240.083C69.238 240.083 52.4583 232.43 44.191 228.348C37.2628 224.927 30.1432 221.806 23.6201 217.662C18.494 214.406 13.3215 211.007 9.1444 206.595C6.3934 203.689 3.85 200.398 2.3827 196.672C1.7697 195.116 1.4652 193.377 1.6208 191.711C1.9408 188.286 3.0915 184.806 5.0493 181.98C9.8163 175.099 17.3234 170.492 24.2868 165.856C32.6471 160.29 42.0291 156.437 51.0479 152.022C65.06 145.162 79.448 139.097 93.523 132.368C100.198 129.176 107.006 126.219 113.427 122.541C117.746 120.067 121.92 117.321 125.903 114.335C128.77 112.186 131.953 110.247 134.093 107.371C137.016 103.443 140.201 98.8119 139.998 93.9179C139.771 88.4579 135.804 83.6029 132.188 79.5109C127.331 74.0139 120.476 70.6549 114.284 66.7269C110.382 64.2509 106.307 62.0489 102.189 59.9529C96.428 57.0189 90.434 54.5659 84.571 51.8429C80.151 49.7909 69.268 44.6459 69.268 44.6459"
            stroke="#272727"
            strokeWidth="2"
          />
          <path
            id="motionPath6"
            className={[styles.path6, animate].join(" ")}
            d="M69.267 44.6439C69.267 44.6439 52.487 36.9909 44.2197 32.9089C37.2915 29.4879 30.1719 26.3669 23.6488 22.2229C18.5226 18.9669 13.3502 15.5679 9.17308 11.1559C6.42208 8.24989 3.87858 4.95889 2.41138 1.23389"
            stroke="#272727"
            strokeWidth="2"
          />
          <circle
            id="circle"
            className={[styles.svgPunto, animate].join(" ")}
            cx="0"
            cy="0"
            r="10"
            fill="#1A202C"
          />
        </g>
      </svg>
    </>
  )
}

export default SvgAnimation
